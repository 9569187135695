import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-v15",
  "date": "2016-04-28",
  "title": "REACT V15",
  "author": "admin",
  "tags": ["development", "javascript", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Facebook released React v15.0.0 on 07/04/2016. The most noticeable change was the version number jump from 0.14 to 15.0."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Major changes include:`}</p>
    <ul>
      <li parentName="ul">{`Version Number`}</li>
      <li parentName="ul">{`Simplified DOM element rendering`}</li>
      <li parentName="ul">{`Improved SVG support`}</li>
      <li parentName="ul">{`New helpful warnings`}</li>
    </ul>
    <h2>{`Version Number`}</h2>
    <p>{`Before v15.0.0, the previous React version dubbed `}<inlineCode parentName="p">{`React 14`}</inlineCode>{` or 0.14.x had the leading 0 has caused some confusion in the community, leading some to believe that it wasn’t yet ready to use in production.`}</p>
    <Message type="info" title="" content="Version naming change helps indicate our commitment to stability and gives us the flexibility to add new backwards-compatible features in minor releases – **Sebastine Markbåge**" mdxType="Message" />
    <p>{`The project will continue to follow the `}<inlineCode parentName="p">{`semver`}</inlineCode>{` guidelines, in the future versions. That means the major version will change for the breaking changes, backward compatible versions will have a minor version change and the bug fixes will increase the patch version.`}</p>
    <h2>{`Simplified DOM element rendering`}</h2>
    <p>{`The way React renders the elements has changed in two areas:`}</p>
    <ul>
      <li parentName="ul">{`No more `}<inlineCode parentName="li">{`data-reactid`}</inlineCode>{` attributes`}</li>
      <li parentName="ul">{`No more `}<inlineCode parentName="li">{`span`}</inlineCode>{` elements (for plain text)`}</li>
    </ul>
    <p>{`Let’s compare the render output in different versions:`}</p>
    <h3>{`v0.14.0`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div data-reactid=".0.0.5" >
  <span data-reactid=".0.0.5.0">Hello</span>
  <span data-reactid=".0.0.5.1">world</span>
</div>
`}</code></pre>
    <h3>{`v15.0.0`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>Hello world</div>
`}</code></pre>
    <p>{`Removal of spans helps to clear DOM clutter as well as reduces the file size of components.`}</p>
    <h2>{`Improved SVG support`}</h2>
    <p>{`In React v15 claims to support all the SVG attributes that are recognized by today’s browsers.`}</p>
    <Message type="info" title="" content="The RC version left us in an inconsistent state for our HTML and SVG behaviors. That’s not awesome as there are already a number of things that must be learned for React and throwing in another inconsistency doesn’t help. So we’ll backpedal for now but still continue to provide full SVG support by going back to our previous whitelist and expanding it. – **Paul O’Shannessy**" mdxType="Message" />
    <h2>{`New helpful warnings`}</h2>
    <p>{`There are number of useful warnings added to React v15.`}</p>
    <p>{`If your styles contain width for example, you can pass in a numeric value as a number without the unit(eg: px). But if you pass in the value as a string, React will warn you.`}</p>
    <h3>{`Correct`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div style={{width: 20}}></div>
`}</code></pre>
    <h3><inlineCode parentName="h3">{`Warning`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div style={{width: "20"}}></div>
`}</code></pre>
    <p>{`As for event handlers, the event handler names are case sensitive. Incorrect case would prompt React to throw a warning.`}</p>
    <h3>{`Correct`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button onClick={{onClick}}></button>

`}</code></pre>
    <h3><inlineCode parentName="h3">{`Warning`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button onclick={{onclick}}></button>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      